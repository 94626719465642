<template>
  <div class="centerTopContainer">
    <div class="topTitle">
      <span class="innerTitle">累计运动总人数</span>
    </div>
    <div class="topPlate">
      <light-plate :lightNum="totalNumber" />
    </div>
    <div class="topDesc">
      <div>
        今日开放学校：
        <span style="font-size: 20px; font-weight: bold">{{
          open_count
        }}</span>
        所
        <span style="margin-right: 20px"></span>
        运动总人数：
        <span style="font-size: 20px; font-weight: bold">{{ sport_count }}</span>
      </div>
    </div>

    <div class="cards">
      <div class="card-item">
        <div class="info">
          <div class="icon"></div>
          <div class="title">开放学校</div>
        </div>
        <div class="line">
          <div class="line-left"></div>
        </div>
        <div class="desc">
          昨日<span>{{ chainBase?.open?.yesterday }}</span>所({{
            chainBase?.open?.yesterdayChainBase + "%"
          }})，本月<span>{{ chainBase?.open?.month }}</span>所({{
            chainBase?.open?.lastMonthChainBase + "%"
          }})
        </div>
      </div>
      <div class="card-item">
        <div class="info">
          <div class="icon"></div>
          <div class="title">服务人数</div>
        </div>
        <div class="line">
          <div class="line-left"></div>
        </div>
        <div class="desc">
          昨日<span>{{ chainBase?.user?.yesterday }}</span
          >人({{ chainBase?.user?.yesterdayChainBase + "%" }})，本月<span>{{
            chainBase?.user?.month
          }}</span
          >人({{ chainBase?.user?.lastMonthChainBase + "%" }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightPlate from "@/components/lightPlate";
import service from "@/utils/request";
export default {
  components: {
    LightPlate,
  },
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      timing: null,
      totalNumber: [0, 0, 0, 0, 0, 0, 0, 0],
      open_count: 0,
      sport_count: 0,
      chainBase: {},
    };
  },
  mounted() {
    // this.getData();
    // this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service
        .get(`/school/sportCount?area_count=${this.code}`)
        .then((response) => {
          if (response.code != 200) {
            console.log(response.message);
            return false;
          }
          const data = response.data;
          console.log("_centerTop", data);
          let total = data.all_count + "";
          if (total.length < 8) {
            const length = 8 - total.length;
            for (let i = 0; i < length; i++) {
              total = "0" + total;
            }
          }
          const compare = data.month_total - data.last_month_total;
          if (compare < 0) myThis.arrowClass = "iconfont icon-xiangxia3";
          if (compare > 0) myThis.arrowClass = "iconfont icon-shang";
          myThis.totalNumber = total.split("");
          myThis.open_count = data.open_count;
          myThis.sport_count = data.sport_count;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    getChainBase() {
      service.get(`/school/chainBase`).then((resp) => {
        console.log("_school/chainBase", resp.data);
        this.chainBase = resp.data;
      });
    },
  },
  watch: {
    code: {
      handler(newV, oldV) {
        console.log("_watch code", newV);
        this.getData();
        this.getChainBase();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.centerTopContainer {
  width: 100%;
  text-align: center;
  .topTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0px 2px 7px 0px #0d65cd;
    .innerTitle {
      &::before {
        content: "";
        margin-right: 5px;
        display: inline-block;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #709efb;
      }
      &::after {
        content: "";
        margin-left: 5px;
        display: inline-block;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 10px solid #709efb;
      }
    }
  }
  .topPlate {
    width: 73%;
    height: 69px;
    margin: 0 auto;
    margin-top: 13px;
  }
  .topDesc {
    background: rgba(59, 125, 213, 0.16);
    width: 62.6%;
    margin: 0 auto;
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    background-size: cover;
    padding: 8px 0;
  }
}

.cards {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .card-item {
    padding: 14px 16px;
    // width: 339px;
    width: 360px;
    height: 110px;
    background: rgba(59, 125, 213, 0.16);
    border-radius: 9px;
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon {
        flex: 0 0 54px;
        margin-right: 18px;
        width: 54px;
        height: 44px;
        background: url("../images/center-top-icon1.png") no-repeat center/54px
          44px;
      }
      .title {
        font-size: 22px;
        text-align: left !important;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .line {
      position: relative;
      margin: 10px 0 6px;
      height: 2px;
      width: 289px;
      border: 1px solid;
      border-image: linear-gradient(
          90deg,
          rgba(68, 137, 253, 0.8),
          rgba(68, 137, 253, 0) 100%
        )
        2 2;
      .line-left {
        position: absolute;
        left: -1px;
        top: -4px;
        width: 65px;
        height: 4px;
        background: #4489fd;
      }
    }
    .desc {
      text-align: left;
      font-size: 14px;
      span {
        font-size: 22px;
        font-weight: 500;
        color: #86d7e5;
      }
    }

    &:nth-of-type(2) {
      .info {
        .icon {
          background-image: url("../images/center-top-icon2.png");
        }
      }
    }
  }
}
</style>
