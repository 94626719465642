<template>
  <div class="right-session-1">
    <div class="city-rank-wrapper d-flex flex-column">
      <div class="city-rank-list-content">
        <rank-list
          :listHeight="880"
          :tableTitle="areaTableTitleList"
          :list="cityRankOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rankList from "@/components/rankList";
import service from "@/utils/request";

export default {
  components: {
    rankList,
  },
  props: {
    schoolRanking: {
      type: Array | Object,
      default: () => [],
    },
  },
  data() {
    return {
      areaTableTitleList: [
        { title: "排名", width: "80" },
        { title: "学校名称", val: "first", width: "180" },
        { title: "使用人数", val: "second", width: "180" },
      ],
      cityRankOptions: [],
    };
  },
  methods: {
    init() {},
  },
  watch: {
    schoolRanking: {
      handler(newV, oldV) {
        console.log("_watch openRanking", newV);
        if (Array.isArray(newV)) {
          this.cityRankOptions = newV.map((item) => ({
            first: item.title,
            second: item.open_num,
          }));
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.right-session-1 {
  margin-top: 24px;
  color: #ffffff;
}
</style>
