<template>
  <div class="left-session-2">
    <Echart
      :options="options"
      id="bottomLeftChart1"
      height="220px"
      width="100%"
    ></Echart>
    <!-- <div class="data-distribute d-flex h-100 w-100">
      <div class="distribute-left-content w-100">
        <div class="distribute-img"></div>
        <div
          v-for="(item, index) in ageData"
          :key="index"
          :class="`distribute-process-item line-${index} distribute-center-text`"
        >
          {{ (item.value / item.total).toFixed(2) }}%
        </div>
      </div>
      <div class="distribute-right-content d-flex flex-column">
        <div
          v-for="(item, index) in ageData"
          :key="index"
          :class="`distribute-data-item line-${index}`"
        >
          {{ item.name }}岁
          <span style="font-weight: bold; font-size: 16px">
            {{ item.value }} </span
          >人
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import echarts from "echarts";
import Echart from "@/common/echart";

export default {
  props: {
    userProportion: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Echart,
  },
  data() {
    return {
      ageData: [],
      options: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#303030",
          borderColor: "#2DCDFF", // 修改字体颜色
          borderWidth: 1,
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            console.log("_params", params);
            let res = "";
            res = `
                        <div style="padding:8px 12px;">
                          <div>人流量年龄分布(${params[0].axisValue})：</div>
                          <div style="font-size: 12px;padding-top: 6px">人数：${params[0]?.data}人</div>
                        </div>`;
            return res;
          },
        },
        grid: {
          top: "18%",
          left: "8%",
          right: "0%",
          bottom: "10%",
        },
        xAxis: {
          data: ["20以下", "20~29", "30~39", "40~49", "50~59", "60以上"],
        },
        yAxis: [
          {
            name: "万人",
            // data: "value",
            type: "value",
            axisLabel: {
              formatter: function (v) {
                return v / 10000;
              },
            },
          },
        ],
        series: [
          {
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(8,253,254,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(8,253,254,1)",
                    },
                  ],
                  false
                ),
              },
            },
            data: [],
            type: "bar",
            barWidth: 10,
            label: {
              show: true,
              position: "top",
              color:"#ffffff"
            },
          },
          {
            data: [],
            type: "line",
            itemStyle: {
              color: "#056FEB",
            },
            lineStyle: {
              width: 2,
            },
          },
        ],
      },
    };
  },
  methods: {
    init() {},
  },
  watch: {
    userProportion: {
      handler(newV, oldV) {
        if (newV && newV.age) {
          let data = newV.age;
          let total = data.reduce((prev, curr, arr) => {
            let p = Number(prev) + Number(curr.value);
            return p;
          }, 0);
          data = data.map((item) => {
            item.total = total;
            return item;
          });
          console.log("_watch age", data);

          const oneData = data.map((item) => item.value);
          this.options.series[0].data = oneData;
          this.options.series[1].data = oneData;

          this.ageData = data;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.left-session-2 {
  height: 220px;
}
.data-distribute {
  .line-0 {
    top: 50px;
  }
  .line-1 {
    top: 116px;
  }
  .line-2 {
    top: 171px;
  }
  .line-3 {
    bottom: 58px;
  }
  .line-4 {
    bottom: 14px;
  }
  .distribute-left-content {
    width: 270px;
    position: relative;
    .distribute-img {
      display: inline-block;
      height: 100%;
      width: 263px;
      background: url("~@/assets/matchActivity/distribute.png") 100% 100%
        no-repeat;
    }
    .distribute-process-item {
      position: absolute;
      left: 70px;
    }
  }
  .distribute-right-content {
    font-size: 12px;
    width: 150px;
    position: relative;
    .distribute-data-item {
      position: absolute;
    }
  }
}
</style>
